.select-edit-mode-container {
  position: relative;

  select {
    padding: 5px 8px;
  }

  .edit-mode-buttons-container {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    z-index: 10;
  }
}

.select-display-mode-container {
  padding: 3px 4px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

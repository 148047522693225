.show-more-container {
  position: relative;
}

.show-more-content {
  transition: max-height 0.3s ease;
  border: 1px solid lightgray;
  padding: 1rem;
  margin-bottom: 1rem;

  &.collapsed.overflowing {
    border-bottom: none;

    &::after {
      content: '';
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      height: 50%; /* Adjust the fade height as needed */
      background: linear-gradient(to bottom, transparent, white); /* Adjust 'white' to match the background */
      pointer-events: none; /* Ensure the overlay doesn't interfere with user interaction */
    }
  }

  &.expanded {
    border-bottom: 1px solid lightgray;
  }
}

.show-more-button {
  background: none;
  border: none;
  color: #3273dc;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #275aa8;
  }
}

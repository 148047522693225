@use '~bulma/bulma';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .section {
    padding: 1.5rem;

    .column-divider {
      border-left: 2px solid #f4f4f6;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 10;

    .react-datepicker {
      font-family: 'Arial', sans-serif;
      border: 1px solid var(--bulma-border);
      border-radius: 4px;
      box-shadow: 0 2px 3px var(--bulma-grey-dark-rgb);

      .react-datepicker__header {
        background-color: var(--bulma-primary);
        border-bottom: 1px solidvar(--bulma-border);
        color: var(--bulma-white);
        font-size: 1rem;

        .react-datepicker__current-month {
          font-weight: bold;
          font-size: 1rem;
        }
      }

      .react-datepicker__day,
      .react-datepicker__day-name {
        margin: 0.2rem;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        border-radius: 4px;
      }
      .react-datepicker__day--selected {
        background-color: var(--bulma-primary);
        color: white;
      }
      .react-datepicker__day:hover {
        background-color: var(--bulma-primary-light);
        color: var(--bulma-white);
      }
    }
  }
}

@use 'bulma/sass/utilities/mixins';

.job-application-list-container {
  .clear-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
  }

  .table tr.no-bottom-border {
    td {
      border-bottom: none;
    }
  }
  .highlighted-notes-container {
    .highlighted-note {
      background-color: var(--bulma-light);
      padding: 0.25rem;
      border-radius: 6px;

      .highlight {
        background-color: yellow;
        font-weight: bold;
      }
    }
  }
}

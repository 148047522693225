.date-edit-mode-container {
  position: relative;

  .edit-mode-buttons-container {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    z-index: 1;
  }
}

.date-display-mode-container {
  border: 1px dashed transparent;
  padding: 5px 8px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  &:hover {
    border: 1px dashed #999;
  }
}

.message {
  .message-body {
    position: relative;
    min-height: 75px;
    padding-bottom: 1rem;

    .corner-top-right {
      float: right;
      margin: -0.5rem -0.5rem 0.5rem 0.5rem;
    }
    .corner-bottom-right {
      position: absolute;
      bottom: 6px;
      right: 12px;
      font-size: 0.75rem;
    }
  }

  margin-bottom: 0.25rem;
}

.data-import-section {
  .tabs {
    li {
      pointer-events: none;
      cursor: default;
    }
  }

  .container.step {
    display: none;

    &.is-active {
      display: block;
    }
  }
}
